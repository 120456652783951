import { HttpHandler, HttpInterceptor, HttpRequest, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from 'src/app/auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AddTokenInterceptor implements HttpInterceptor {
  private totalRequests = 0;

  constructor(private router: Router, private authService: AuthService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!request.url.includes('api.mapbox.com')) {
      const serverUrl = environment.serverUrl + request.url;
      if (request.url.includes('resetUserPassword')) {
        request = request.clone({
          url: serverUrl
        });
      } else if (request.url.includes('oauth')) {
        const basicAuthToken = `Basic ${btoa('iot-client:secret')}`; // base 64 encode mechanism
        request = request.clone({
          setHeaders: {
            Authorization: basicAuthToken
          },
          url: serverUrl
        });
      } else {
        const accessToken = sessionStorage.getItem('accessToken');
        request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${accessToken}`
          },
          url: serverUrl
        });
      }
    }

    return next.handle(request).pipe(
      catchError((error: any) => {
        if (error.status !== 401 && error.status !== 403) return throwError(() => new Error(error));
        else {
          // 401 - UnAuthorized, 403 - Access Denied
          if (error.status == 401) {
            sessionStorage.removeItem('accessToken');
            this.totalRequests = 0;
            this.authService.signOut();

            this.router.navigateByUrl('/auth/login');
          } else if (error.status == 403) {
            this.router.navigateByUrl('/auth/access-denied');
          }

          return throwError(() => new Error(error));
        }
      })
    );
  }
}
