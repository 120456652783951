import { monthCalender } from '../shared/param-util';

var moment = require('moment');
let momentDurationFormatSetup = require('moment-duration-format');
momentDurationFormatSetup(moment);
export function isNullOrUndefined(object: any): object is null | undefined {
  return (
    object === null ||
    object === undefined ||
    (typeof object == 'string' && (object == 'null' || object == 'undefined'))
  );
}

export function getCurrentDateInDDMONYYYY() {
  var todayDate = new Date().toISOString().split('T')[0];
  var todayDateSplit = todayDate.split('-');
  return todayDateSplit[2] + ' ' + monthCalender[Number(todayDateSplit[1]) - 1].name + ', ' + todayDateSplit[0];
}

export function getTotalForLabel(res: any, type: string) {
  var dateList = [];
  var today = new Date();
  var yesterday = new Date(today);
  var dayBeforeYesterday = new Date(today);
  yesterday.setDate(yesterday.getDate() - 1);
  dayBeforeYesterday.setDate(dayBeforeYesterday.getDate() - 2);
  dateList.push(yesterday.toISOString().split('T')[0]);
  dateList.push(dayBeforeYesterday.toISOString().split('T')[0]);
  var dataList: any = [];
  dateList.forEach((date) => {
    const data = res[date];
    let mainSum = 0;
    for (let i in data) {
      mainSum += data[i][type];
    }
    dataList.push(mainSum);
  });
  const result = ((dataList[0] - dataList[1]) / dataList[1]) * 100;
  if (result < 0) {
    return [Math.abs(result).toFixed(2), 'down'];
  } else {
    return [Math.abs(result).toFixed(2), 'up'];
  }
}

export function msToTime(ms: any) {
  var hours = parseInt((ms / 3600).toString());
  ms = ms % 3600;
  var minutes = parseInt((ms / 60).toString());
  var hours1 = hours.toString().length === 1 ? '0' + hours : hours;
  var minutes1 = minutes.toString().length === 1 ? '0' + minutes : minutes;
  return hours1 + ':' + minutes1;
}

export function getTimeInHHMMSS(seconds: number, format: string) {
  return moment.duration(seconds, 'second').format(format, { trim: false });
}

export function getAverage(total: number, count: number, numberFormat: boolean = true) {
  const average = total / count;
  if (numberFormat) {
    return !average.toString().localeCompare('NaN')
      ? 0
      : Intl.NumberFormat('en-US').format(parseInt(average.toFixed(0)));
  } else {
    return !average.toString().localeCompare('NaN') ? 0 : average.toFixed(2);
  }
}

export function getAverageTotal(res: any, keys: any, field: string, trend: boolean, numberFormat: boolean = true) {
  let finalSum = 0;
  let count = 0;
  keys.forEach((key: any) => {
    res[key].forEach((ele: any) => {
      finalSum += ele[field];
      count += 1;
    });
  });
  var average: number;
  finalSum = parseFloat(finalSum.toFixed(2));
  if (trend) {
    average = finalSum / keys.length;
  } else {
    average = finalSum / count;
  }
  const average1 = parseFloat((parseInt((average * 100).toString()) / 100).toFixed(2));
  if (numberFormat) {
    return [
      !average1.toString().localeCompare('NaN') ? 0 : Intl.NumberFormat('en-US').format(parseInt(average1.toFixed(0))),
      !finalSum.toString().localeCompare('NaN') ? 0 : Intl.NumberFormat('en-US').format(parseInt(finalSum.toFixed(0)))
    ];
  } else {
    return [
      !average1.toString().localeCompare('NaN') ? 0 : average1,
      !finalSum.toString().localeCompare('NaN') ? 0 : finalSum
    ];
  }
}

export function getdatelast7days() {
  var date = new Date();
  date.setDate(date.getDate() - 6);
  var todayDate = date.toISOString().split('T')[0];
  var todayDateSplit = todayDate.split('-');
  return todayDateSplit[2] + ' ' + monthCalender[Number(todayDateSplit[1]) - 1].name + ', ' + todayDateSplit[0];
}

export function isObjectEmpty(obj: Object): boolean {
  for (let prop in obj) {
    if (obj.hasOwnProperty(prop)) {
      return false;
    }
  }
  return true;
}

export function getLastUpdatedMessage(fromDate: Date): string {
  let data = getDateDiff(fromDate, new Date());
  let msg = '';
  if (data.day >= 2) {
    msg = `Updated on ${moment(fromDate).format('DD MMM YYYY')}`;
  } else if (data.day == 1) {
    msg = `Updated ${data.day} Days and ${data.hour} Hours ago`;
  } else if (data.hour > 0) {
    msg = `Updated ${data.hour} Hours and ${data.minute} Minutes ago`;
  } else if (data.minute > 0) {
    msg = `Updated ${data.minute} Minutes ago`;
  } else if (data.second > 0) {
    msg = `Updated ${data.second} Seconds ago`;
  }
  return msg;
}

export function getDateDiff(startDate: Date, endDate: Date) {
  var diff = endDate.getTime() - startDate.getTime();
  var days = Math.floor(diff / (60 * 60 * 24 * 1000));
  var hours = Math.floor(diff / (60 * 60 * 1000)) - days * 24;
  var minutes = Math.floor(diff / (60 * 1000)) - (days * 24 * 60 + hours * 60);
  var seconds = Math.floor(diff / 1000) - (days * 24 * 60 * 60 + hours * 60 * 60 + minutes * 60);
  return { day: days, hour: hours, minute: minutes, second: seconds };
}
